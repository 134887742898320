@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.panel {
  @include gutter-sizing('margin-bottom');

  .card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
