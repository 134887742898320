@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.form-actions {
  position: sticky;
  top: 92px;

  .btn-save-changes {
    margin-bottom: 16px;

    @media screen and (min-width: $bp-md) {
      margin-bottom: 20px;
    }
  }
}
