@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.tabs {
  position: relative;

  .tabs-wrap {
    @include gutter-sizing('padding-bottom');
    min-width: min-content;
  }

  .nav-tabs {
    flex-wrap: nowrap;

    &.sticky-nav {
      @include themify($themes) {
        background-color: themed('cardBackground');
        box-shadow: 0 1px 30px themed('cardBackground');
      }
      position: sticky;
      top: 59px;
      z-index: 100;
    }

    li {
      .nav-link {
        background-color: transparent;
        cursor: pointer;
        margin: 0 4px;
        transition: background-color 0.3s, border-color 0.3s;

        @include themify($themes) {
          color: themed('cardText');
        }

        &:hover,
        &:focus,
        &:active {
          background-color: rgba($text-color, 0.05);
          border-color: $border-color-light;
          outline: none;
        }

        &.active,
        &.active:focus,
        &.active:hover {
          background-color: transparent;
          border-color: $border-color-light;

          @include themify($themes) {
            border-bottom-color: themed('cardBackground');
          }
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }
  }

  .tab-pane {
    @include gutter-sizing('padding-top');
    overflow-x: hidden;
  }

  &.bordered-top .nav-tabs li .nav-link {
    border-top: 2px solid transparent;
    border-radius: 0;

    &.active,
    &:focus,
    &:hover {
      border-top: 2px solid $color-accent;
    }
  }

  &.bordered-bottom .nav-tabs li .nav-link {
    border-color: transparent;
    border-left: 0;
    border-right: 0;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    margin: 0;

    &.active {
      &,
      &:focus,
      &:hover {
        border-radius: 0;
        border-color: transparent;
        border-bottom: 3px solid $color-accent;
      }
    }
  }

  &.fieldset {
    .nav-tabs {
      border: 0;
      border-top: 1px solid $border-color;

      li .nav-link {
        @include gutter-sizing('margin-right');
        background-color: transparent;
        border: 0;
        border-top: 3px solid transparent;
        color: $text-color-muted;
        font-size: 20px;
        line-height: (26/20);
        margin-left: 0;
        padding: 13px 0 6px;
        position: relative;

        @media screen and (min-width: $bp-md) {
          font-size: $h3-font-size;
          line-height: (30/24);
          padding: 17px 0 20px;
        }

        &:focus,
        &:hover {
          border-radius: 0;
          @include themify($themes) {
            border-top: 3px solid themed('colorBorder');
          }
        }

        &.active,
        &.active:focus,
        &.active:hover {
          @include themify($themes) {
            color: themed('cardText');
            border-top: 3px solid themed('colorBorder');
          }
          border-radius: 0;
        }
      }
    }

    .tab-pane {
      padding-top: 0;
    }
  }

  .tab-buttons {
    @include gutter-sizing([ 'padding-top', 'padding-bottom' ]);
    border-top: 1px solid $border-color-light;
    display: flex;
    justify-content: space-between;
    overflow: visible;
  }
}

// Fix for franchise input at the bottom of Movies form

.form {
  .tabs,
  .tabs-wrap,
  .tab-content,
  .tab-pane {
    overflow: visible;
  }
}
