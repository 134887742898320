@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.asset-card {
  appearance: none;
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
  overflow: hidden;
  display: flex;
  width: 100%;
  text-align: left;
  align-items: normal;
  padding: 0;

  &.card-body {
    height: auto;
  }

  .thumbnail {
    text-align: center;
    border-bottom: 1px solid $border-color-light;
    padding-bottom: 9 / 16 * 100%;
    height: 0;
    position: relative;

    .image-container {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
      }
    }

    .asset-icon {
      font-size: 5.5rem;
      height: 5.5rem;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 5.5rem;

      &.folder-icon {
        color: $yellow;
      }
    }
  }

  .card-body {
    min-height: 61px;
    padding: rem(8) rem(10);

    @media screen and (min-width: $bp-md) {
      min-height: 66px;
      padding: rem(8) rem(12);
    }

    @media screen and (min-width: $bp-xl) {
      min-height: 78px;
      padding: rem(12) rem(16);
    }

    .title {
      font-size: $font-size-sm;
      margin-bottom: 2px;

      @media screen and (min-width: $bp-md) {
        font-size: rem(14);
      }

      @media screen and (min-width: $bp-xl) {
        font-size: $font-size-base;
      }
    }

    small {
      color: $text-muted;
      display: block;
      font-size: $font-size-xs;

      @media screen and (min-width: $bp-md) {
        font-size: rem(11);
      }

      @media screen and (min-width: $bp-xl) {
        font-size: $font-size-sm;
      }
    }
  }
}
