@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.btn {
  position: relative;
  white-space: nowrap;

  svg {
    fill: currentColor;
  }

  .mdi-icon {
    display: inline-block;
    margin: -2px -0.25em 0 0;
    height: (($btn-line-height - 0.1) * 1em);
  }

  &-sm .mdi-icon {
    height: (($btn-line-height-sm - 0.1) * 1em);
  }

  &-lg .mdi-icon {
    height: (($btn-line-height-lg - 0.1) * 1em);
  }

  &.btn-icon {
    height: 40px;
    line-height: 1;
    padding: 0;
    width: 40px;

    &.btn-sm {
      height: 24px;
      width: 24px;
    }

    &.btn-lg {
      font-size: rem(22);
      height: 60px;
      width: 60px;
    }

    .mdi-icon {
      margin: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &.btn-light:focus {
    box-shadow: 0 0 0 $input-btn-focus-width rgba($text-color, 0.4);
  }
}

a.btn {
  appearance: none;
  // Looks like this isn't being added by PostCSS as it should
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
}

.loading {
  cursor: default;
}

.loading-indicator {
  background-color: inherit;
  bottom: 0;
  color: inherit;
  font-size: 1.75em;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.loading-indicator-outline {
  background-color: currentColor;
}

.loading-indicator-link {
  background-color: transparent;
  color: transparent;
}

.loading-indicator-link .ellipsis-1,
.loading-indicator-link .ellipsis-2,
.loading-indicator-link .ellipsis-3,
.loading-indicator-link .ellipsis-4 {
  background-color: black;
}

.ellipsis-container {
  height: 11px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;
}

.ellipsis-1,
.ellipsis-2,
.ellipsis-3,
.ellipsis-4 {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: white;
  border-radius: 50%;
  height: 11px;
  position: absolute;
  top: 0;
  width: 11px;
}

.ellipsis-1 {
  left: 6px;
  animation: ellipsis1 0.6s infinite;
}

.ellipsis-2 {
  left: 6px;
  animation: ellipsis2 0.6s infinite;
}

.ellipsis-3 {
  left: 26px;
  animation: ellipsis2 0.6s infinite;
}

.ellipsis-4 {
  left: 45px;
  animation: ellipsis3 0.6s infinite;
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
