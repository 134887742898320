@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.dropzone {
  @include transition($btn-transition);
  border-radius: $border-radius-sm;
  cursor: pointer;
  display: flex;
  min-height: 80px;
  position: relative;
  width: 100%;

  @include themify($themes) {
    background-color: themed('cardColorHover');
    border: 1px solid themed('colorFieldsBorder');
  }

  &.form-control {
    height: auto;
  }

  &.thumbnail {
    height: 128px;
    overflow: hidden;
    width: 128px;

    .files-wrapper {
      padding: 0;
    }

    .dropzone-drop-here {
      font-size: 11px;
      line-height: (14/11);
      padding: 0 8px;
    }

    .dropzone-file {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      img {
        left: 50%;
        margin: auto;
        max-height: none;
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .remove {
      bottom: 12px;
      height: auto;
      left: 50%;
      top: auto;
      transform: translateX(-50%);
    }
  }

  .dropzone-input {
    cursor: pointer;
    display: flex;
    height: 100%;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;
  }

  .dropzone-drop-here {
    color: $text-color;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    margin: auto;
    padding: $btn-padding-y $btn-padding-x;
    text-align: center;
  }

  .files-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 0 12px;
    width: calc(100% + 30px);
  }
}
