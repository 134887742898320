@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$blur-fill-value: 40px;

.image-component {
  // vertical center
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  .image-size-container {
    // horizontal center
    max-height: 100%;
    max-width: 100%;
  }

  img {
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }

  &.blur-fill {
    .background {
      display: none;

      @supports (filter: blur()) {
        background-size: cover;
        bottom: -$blur-fill-value * 2;
        display: block;
        filter: brightness(0.4) blur($blur-fill-value);
        left: -$blur-fill-value * 2;
        position: absolute;
        right: -$blur-fill-value * 2;
        top: -$blur-fill-value * 2;
        z-index: 0;
      }
    }
    @supports (filter: blur()) {
      img {
        box-shadow: $box-shadow-dark;
      }
    }

    &.lighten .background {
      filter: brightness(5) blur($blur-fill-value);
    }
  }
}
