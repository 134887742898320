@charset "UTF-8";
:export {
  primary: #49C6E8;
  secondary: #767676;
  success: #8FC540;
  info: #002E6D;
  warning: #F5C730;
  danger: #E53322;
  light: #F6F6F6;
  dark: #1C1C21;
  purple: #6621A9;
  yellow: #F5C730;
  orange: #FC723C;
  green: #8FC540;
  red: #E53322;
  cyan: #49C6E8;
  blue: #002E6D;
  chart-color-1: #49C6E8;
  chart-color-2: #20C997;
  chart-color-3: #8FC540;
  chart-color-4: #F5C730;
  chart-color-5: #FC723C;
  chart-color-6: #E53322;
  chart-color-7: #E83E8C;
  chart-color-8: #6621A9;
  chart-color-9: #6610F2;
  chart-color-0: #002E6D;
  chart-color-hc-1: #49C6E8;
  chart-color-hc-2: #F5C730;
  chart-color-hc-3: #E83E8C;
  chart-color-hc-4: #6610F2;
  chart-color-hc-5: #20C997;
  chart-color-hc-6: #FC723C;
  chart-color-hc-7: #6621A9;
  chart-color-hc-8: #8FC540;
  chart-color-hc-9: #E53322;
  chart-color-hc-0: #002E6D; }

@font-face {
  font-family: 'Albra';
  src: url("https://1493499192.rsc.cdn77.org/assets/emails/fonts/Albra-Grotesk-Bold.woff2") format("woff2"), url("https://1493499192.rsc.cdn77.org/assets/emails/fonts/Albra-Grotesk-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  mso-font-alt: 'Arial'; }

@font-face {
  font-family: 'Albra';
  src: url("https://1493499192.rsc.cdn77.org/assets/emails/fonts/Albra-Grotesk-Regular.woff2") format("woff2"), url("https://1493499192.rsc.cdn77.org/assets/emails/fonts/Albra-Grotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  mso-font-alt: 'Arial'; }

@font-face {
  font-family: 'Albra';
  src: url("https://1493499192.rsc.cdn77.org/assets/emails/fonts/Albra-Grotesk-Light.woff2") format("woff2"), url("https://1493499192.rsc.cdn77.org/assets/emails/fonts/Albra-Grotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  mso-font-alt: 'Arial'; }

.rc-notification {
  height: 0;
  left: auto !important;
  position: fixed;
  right: 20px;
  top: 20px !important;
  z-index: 101; }

.rc-notification-notice-content {
  display: flex; }

.rc-notification-notice-close-x:after {
  content: '×'; }

.rc-notification-fade-enter {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused; }

.rc-notification-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused; }

.rc-notification-fade-enter.rc-notification-fade-enter-active {
  animation-name: rcNotificationFadeIn;
  animation-play-state: running; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.right-up {
    animation-name: rcNotificationRightFadeIn; }
  .rc-notification-fade-enter.rc-notification-fade-enter-active.left-up {
    animation-name: rcNotificationLeftFadeIn; }

.rc-notification-fade-leave.rc-notification-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running; }

@keyframes rcNotificationFadeIn {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes rcNotificationLeftFadeIn {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcNotificationRightFadeIn {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes rcDialogFadeOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(-100%);
    opacity: 0; } }
