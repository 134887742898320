@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.alert {
  align-items: center;
  border-radius: 0;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0;

  &.fade {
    opacity: 1;
  }

  p {
    margin-bottom: 0;
  }

  .alert__content {
    flex: 1;
    padding: 10px 20px;
  }

  &.alert-info {
    background-color: $color-blue;
    border-color: $color-blue;

    &.alert--colored .alert__icon {
      padding-right: 0;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-blue;
    }
  }

  &.alert-success {
    background-color: $color-accent;
    border-color: $color-accent;

    &.alert--colored .alert__icon {
      padding-right: 0;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-accent;
    }
  }

  &.alert-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;

    &.alert--colored .alert__icon {
      padding-right: 0;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-yellow;
    }
  }

  &.alert-danger {
    background-color: $color-red;
    border-color: $color-red;

    &.alert--colored .alert__icon {
      padding-right: 0;
    }

    &.alert--neutral .alert__icon svg {
      fill: $color-red;
    }
  }

  &.alert--bordered {
    @include themify($themes) {
      background-color: rgba(themed('colorBackground'), 0.7);
      color: themed('colorText');
    }

    .alert__icon {
      padding-right: 0;
    }

    .close {
      @include themify($themes) {
        color: themed('colorText');
      }
    }
  }

  &.alert--neutral {
    background-color: transparent;
    border-color: #F2F4F7;

    .close {
      color: $color-additional;
    }

    svg {
      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    .alert__icon {
      border-right: 1px solid #F2F4F7;
    }
  }

  .alert__icon {
    padding: 18px;
    display: flex;
    font-size: 2em;

    svg {
      fill: currentColor;
      margin: auto 0;
    }
  }

  .close,
  .rc-notification-notice-close {
    align-items: center;
    align-self: stretch;
    background-color: rgba(0, 0, 0, 0.1);
    color: currentColor;
    display: flex;
    font-size: 18px;
    font-weight: 100;
    min-height: 60px;
    justify-content: center;
    justify-self: flex-end;
    line-height: 18px;
    opacity: 1;
    text-shadow: none;
    width: 60px;

    &:focus,
    &:active,
    &:active:focus {
      outline: none;
    }

    &:focus,
    &:hover,
    &:hover:focus,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      background-color: rgba(0, 0, 0, 0.15);
      color: currentColor;
      opacity: 1;
    }
  }
}
