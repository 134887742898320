input {
  @extend .form-control;
}

input,
.form-control {
  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
  }
}

select:focus {
  @include focus-shadow($green);
}

form {
  .horizontal .input-container {
    @media screen and (min-width: $bp-md) {
      padding: 9px 0;
    }
  }

  input,
  textarea,
  .react-select .select__control {
    &.input-icon-left {
      border-radius: $border-radius-sm;
      padding-left: 54px;
    }

    &.input-icon-right,
    &.form-control:not(:last-child),
    &.custom-select:not(:last-child) {
      padding-right: 54px;
    }

    &:placeholder {
      color: $text-color-muted;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-accent;
    }
  }

  textarea {
    min-height: 85px;
    resize: vertical;

    &.form-control::-webkit-scrollbar {
      box-shadow: -1px 1px 7px rgba(black, 0.15) inset;
      height: initial;
      padding: initial;
      width: initial;
    }

    &.form-control::-webkit-scrollbar-thumb {
      border-radius: initial;
      background-color: rgba(black, 0.2);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    height: auto;
  }

  .action-group {
    margin-bottom: 20px;
  }

  .input-group-append,
  .input-group-prepend {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 5px 10px;
    position: absolute;

    .mdi-icon {
      margin: 0; // Overwriting the alignment tweaks that align the icon to text
    }
  }

  .input-group-prepend {
    border-bottom-left-radius: $border-radius-sm;
    border-bottom-right-radius: 0;
    border-top-left-radius: $border-radius-sm;
    border-top-right-radius: 0;
    left: 0;
  }

  .input-group-append {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $border-radius-sm;
    border-top-left-radius: 0;
    border-top-right-radius: $border-radius-sm;
    right: 0;
  }

  &.form--horizontal {
    .form__form-group {
      display: flex;
      flex-wrap: wrap;
    }

    .form__form-group-field {
      width: calc(100% - 80px);
      padding-left: 10px;
      margin-left: 80px;
    }

    .form__form-group-label {
      width: 80px;
      margin: auto 0;

      & + .form__form-group-field {
        margin-left: 0;
      }
    }

    .form__form-group-description {
      margin-left: 90px;
    }

    .form__button-toolbar {
      margin-left: 0;
    }

    @media screen and (min-width: 480px) {
      .form__form-group-label {
        width: 120px;
      }

      .form__form-group-field {
        width: calc(100% - 120px);
        margin-left: 120px;
        padding-left: 20px;
      }

      .form__form-group-description,
      .form__button-toolbar {
        margin-left: 140px;
      }
    }
  }

  &.form--preview {
    display: flex;

    & > div:nth-child(2) {
      margin-right: 50px;
    }

    .form__form-group {
      margin-bottom: 10px;
      width: auto;
      min-height: 18px;
    }

    .form__select-color {
      display: flex;
      flex-wrap: wrap;
      max-width: 84px;
      margin-right: 40px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.form--justify {
    display: flex;
    flex-wrap: wrap;

    .form__form-group {
      width: 33.3333%;
    }

    .form__button-toolbar {
      width: 100%;
    }
  }

  .form__form-group-select {
    width: 100%;
    height: 32px;

    .Select-control {
      height: 32px;
      border-radius: 0;
      transition: all 0.3s;
      background: transparent;

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
      }
    }

    .Select-placeholder,
    .Select-input {
      height: 30px;
    }

    .Select-input {
      width: 100%;
    }

    input {
      width: 100% !important;
      border: none;
      padding: 0;
    }

    .Select-multi-value-wrapper {
      .Select-input {
        width: inherit;
      }

      .Select-value {
        background: transparent;
        border-color: $color-blue;
      }

      .Select-value-label {
        @include themify($themes) {
          color: themed('cardText') !important;
        }
      }

      .Select-value-icon {
        border-color: $color-blue;
      }
    }

    .Select-menu-outer {
      top: calc(100% + 1px);
      border-radius: 0;
      box-shadow: none;
      animation: open 0.3s ease-in-out;
      overflow: hidden;

      @include themify($themes) {
        border: 1px solid themed('colorBorder');
        background: themed('cardColorHover');
      }
    }

    @keyframes open {
      0% {
        max-height: 0;
      }

      100% {
        max-height: 200px;
      }
    }

    .Select-option {
      transition: all 0.3s;
      border-radius: 0;
      display: flex;

      @include themify($themes) {
        background: themed('cardColorHover');
        color: themed('cardText');
      }

      &.is-focused {
        @include themify($themes) {
          background: themed('cardColorHover');
        }
      }
    }

    &.is-focused,
    &.is-focused:not(.is-open) {
      .Select-control {
        border-color: $color-accent;
        box-shadow: none;
        background: transparent;
      }
    }

    .form__form-group-select-color {
      display: block;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: auto 0 auto 5px;
    }
  }
}

.form__form-group {
  @include themify($themes) {
    color: themed('cardText');
    border-bottom: 1px solid themed('colorFormRowBorder');
  }
  padding-bottom: 12px;
  padding-top: 7px;
  position: relative;
  width: 100%;

  @media screen and (min-width: $bp-md) {
    padding-bottom: 9px;
    padding-top: 9px;

    .align-items-top .form__form-group-label {
      padding-top: 16px;
    }
  }

  &.toggle-container {
    padding-bottom: 7px;
  }

  &.borderless,
  &:last-of-type {
    border-bottom: 0;
  }
}

.form__form-group--address {
  input:last-child {
    margin-left: 15px;
    width: 70%;
  }
}

.form__form-group-field {
  width: 100%;
  display: flex;
  margin: auto;
  position: relative;
}

.form__form-group-label {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 6px;
  padding-top: 6px;

  @media screen and (min-width: $bp-md) {
    padding-bottom: 9px;
    padding-top: 9px;
  }

  @include themify($themes) {
    color: themed('cardText');
  }

  .form__form-group-help-text,
  .form__form-group-optional-flag {
    color: $text-color-muted;
  }
}

.form__form-group-button {
  padding: 6px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }

  &.active {
    background: $color-accent;
    border: 1px solid $color-accent;

    svg {
      fill: #FFF;
    }
  }
}

.form__form-group-file {
  label {
    border-radius: 2px;
    line-height: 18px;
    padding: 4px 20px;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('cardText');
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorFieldsBorder');
      }
    }
  }

  span {
    padding-left: 10px;
  }

  input {
    display: none;
  }
}

.form__form-group-icon {
  height: 32px;
  padding: 6px;
  pointer-events: none;

  @include themify($themes) {
    background: themed('cardBackground');
    border: $input-border-width solid $input-border-color;
  }

  svg {
    fill: darken($border-color, 15%);
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
}

.form__form-group-description {
  font-size: 10px;
  color: $color-additional;
  line-height: 13px;
  margin-top: 2px;
}

.form__button-toolbar {
  margin-top: 10px;
}

.form__form-group-input-wrap {
  width: 100%;
}

.form__form-group-error {
  font-size: 10px;
  line-height: 13px;
  color: #AD4444;
  margin-bottom: -5px;
  display: block;
  margin-top: 5px;
}

.form__form-group-date-cvc {
  display: flex;
  width: 100%;

  .form__form-group-date {
    width: 100%;
    margin-right: 20px;
  }

  .form__form-group-cvc {
    max-width: 280px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-date {
      margin-right: 0;
    }

    .form__form-group-cvc {
      max-width: 100%;
    }
  }
}

.form__form-group-id-category {
  width: 100%;
  display: flex;

  .form__form-group-id {
    min-width: 100px;
    width: 40%;
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-id {
      margin-right: 0;
      width: 100%;
    }
  }
}

.form__form-group-price-discount {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-bottom: 0;
  }

  .form__form-group-price {
    margin-right: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    .form__form-group-price {
      margin-right: 0;
    }
  }
}

.radio-button-container {
  .input-container {
    float: left;
  }
}

// Fix validation icon position

.was-validated .form-control:valid,
.was-validated input:valid,
.form-control.is-valid,
input.is-valid,
.was-validated .form-control:invalid,
.was-validated input:invalid,
.form-control.is-invalid,
input.is-invalid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid,
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: right 4px top 7px;
}

.file-preview {
  align-content: center;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  display: flex;
  height: 100px;
  justify-content: center;
  margin-bottom: 12px;
  margin-right: 12px;
  position: relative;
  width: 100px;

  &:before {
    background-color: rgba(25, 25, 25, 0.6);
    bottom: 0;
    content: '';
    cursor: default;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity $transition-duration ease-in-out;
    width: 100%;
    z-index: 2;
    pointer-events: none;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    .remove,
    .file-name {
      opacity: 1;
    }
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .image-component {
    border-radius: ($border-radius - rem(1));
  }

  .file-thumbnail {
    z-index: 1;
  }

  .file-name {
    color: white;
    display: block;
    margin: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity $transition-duration ease-in-out;
    width: 100%;
    z-index: 3;
  }

  .remove {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    transition: opacity $transition-duration ease-in-out;
    z-index: 3;
  }

  .placeholder-icon {
    color: $text-muted;
    font-size: 48px;
    margin: auto;
    transform: translateY(-1px);
  }
}
