.card {
  border: 0;
  box-shadow: $box-shadow;

  &.clip {
    overflow: hidden;
  }

  @include themify($themes) {
    background-color: themed('cardBackground');
    color: themed('cardText');
  }

  &.main-content-card {
    box-shadow: $box-shadow-dark;
  }

  .card {
    @include themify($themes) {
      background-color: themed('cardColorHover');
    }
  }

  // Making sure the header border still shows up

  &.bg-light .card-header,
  &.bg-warning .card-header {
    border-color: rgba($black, 0.075);
  }

  &.top-border {
    border-top: $border-radius-lg solid;
  }

  .card-title {
    margin-top: 2px; // Makes uppercase letters look closer to center
    text-transform: uppercase;
    position: relative;
  }
}

// Removing additional padding added by Bootstrap

.card-img-overlay {
  padding: 0;
}

// Fixing body padding

.card-body {
  @include gutter-sizing('padding');
}

.card-header,
.card-footer,
.card-header + .card-body {
  @include gutter-sizing([ 'padding-bottom', 'padding-top' ], 0.5);
  @include gutter-sizing([ 'padding-left', 'padding-right' ]);
}
