@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.fieldset {
  margin-bottom: 20px;
  padding: 0;

  legend {
    border-top: 1px solid $border-color;
    font-size: 20px;
    line-height: (26/20);
    margin: 0;
    padding: 16px 40px 6px 0;
    position: relative;

    @media screen and (min-width: $bp-md) {
      font-size: $h3-font-size;
      line-height: (30/24);
      padding: 20px 60px 20px 0;
    }

    &.legend-sm {
      font-size: $font-size-base;
    }
  }

  &.padding-top {
    @include gutter-sizing('padding-top');
  }

  .fieldset-right-icon,
  .btn.icon.fieldset-right-icon {
    background-color: transparent;
    border-color: transparent;
    bottom: auto;
    color: inherit;
    font-size: 24px;
    height: 38px;
    margin: -19px -4px -4px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.3s ease-in-out;
    width: 38px;
    z-index: 2;

    &:before {
      background-color: white;
      transition: all 0.3s ease-in-out;
    }

    &:focus {
      @include focus-shadow($red);
    }
  }

  &.closed .fieldset-right-icon,
  &.closed .btn.icon.fieldset-right-icon,
  &.closing .fieldset-right-icon,
  &.closing .btn.icon.fieldset-right-icon {
    transform: rotate(180deg);
  }
}
